.QZ4M4W_contentHeaderRemoveAction {
  text-transform: none;
}

.QZ4M4W_contentHeaderDeleteIcon {
  float: right;
}

.CIqaea_activeList {
  width: 100%;
  margin-top: 50px;
}

.CIqaea_activeList .CIqaea_scroller {
  height: 180px;
}

.CIqaea_selectionList {
  width: 100%;
  margin-top: 10px;
}

.CIqaea_selectionList .CIqaea_scroller {
  height: 250px;
}

.CIqaea_excludedDomainsContainerLabel {
  margin-left: 30px;
}

.I5jz7a_inputFieldLabel {
  margin-left: 30px;
}

.I5jz7a_inputFieldContainer {
  width: 100%;
  margin-top: 50px;
}

._9R42WG_switchComponentContainer {
  width: 100%;
  margin-top: 50px;
}

._9R42WG_switchComponentFieldLabel {
  margin-left: 30px;
}

._6svnnG_contentHeader {
  margin: 30px 0 0 30px;
}

._6svnnG_contentHeaderRemoveAction {
  text-transform: none;
}

._6svnnG_contentHeaderDeleteIcon {
  float: right;
}

._6svnnG_activeRedirectsContainer {
  width: 100%;
  margin: 30px;
}

.DUeU-G_tableComponentContainer {
  min-width: 100%;
  padding: 15px 180px 50px 50px;
}

.brOa7G_tableHeaderComponentContainer {
  min-width: 100%;
  padding: 30px 180px 0 50px;
}

.hjfj_q_contentHeader {
  margin: 30px 0 0 30px;
}

.hjfj_q_contentHeaderRemoveAction {
  text-transform: none;
}

.hjfj_q_contentHeaderDeleteIcon {
  float: right;
}

._2pl4hq_contentHeader {
  margin: 30px 0 0 30px;
}

.CDGkQG_contentHeader {
  margin: 30px;
}

.CDGkQG_editorContainer {
  height: 100%;
  margin-left: 70px;
  position: relative;
}

.CDGkQG_editorBottomButtonsContainer {
  margin-top: 10px;
  margin-left: 50px;
}

.CDGkQG_buttonContainer {
  margin-bottom: 50px;
}

.CDGkQG_container {
  height: 100%;
}

.CDGkQG_titleContainer {
  height: 15%;
}

.CDGkQG_contentContainer {
  height: 85%;
  padding: 2% 0;
}

.M2ZcXG_contentHeader {
  margin: 30px;
}

.pz8y5W_contentHeader, .pz8y5W_container {
  margin: 30px;
}

.C0NMEq_contentContainer {
  height: 100%;
  background: #e6e6e6;
  padding-top: 134px;
}

.C0NMEq_activeContentContainer {
  height: 100%;
  overflow-y: auto;
}

.lLLhKW_header {
  width: 100%;
  z-index: 1100;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
}

.lLLhKW_iconContainer {
  margin: 10px;
}

.lLLhKW_icon {
  overflow: visible;
}

.lLLhKW_icon .lLLhKW_active {
  transform-origin: 20px 20px;
  animation: lLLhKW_spinning 3s infinite;
}

@keyframes lLLhKW_spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

._0duzUa_navigationBarContainer {
  width: 100%;
  z-index: 10;
  background: #d5dbde;
  position: fixed;
  top: 66px;
  left: 0;
}

._0duzUa_tenantSelectorItem {
  color: #000;
  text-transform: capitalize;
}

.UpsTma_editorContainer {
  margin: 0 50px;
}

.codeMarker {
  z-index: 20;
  background: #fff677;
  position: absolute;
}

._90VnUq_sidebarContainer {
  height: 100vh;
  padding-top: 130px;
  position: relative;
  overflow: hidden;
}

._90VnUq_previewButton {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
}

._90VnUq_deleteCacheButton {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

._90VnUq_buttonHr {
  border-top: 2px solid #e6e6e6;
  margin: 0 0 10px;
}

.TMV4Aq_sideBar {
  width: 25%;
  height: 100vh;
  position: fixed;
}

.TMV4Aq_content {
  height: 100vh;
  margin-left: 25% !important;
}

/*# sourceMappingURL=index.a4afadd2.css.map */
